@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Lato&family=Pacifico&family=Roboto:wght@300;400&family=Rubik&display=swap');



* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Lato', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a:hover {
  text-decoration: none;
}

.tiny_size {
  font-size: 11px;
}

.small_text {
  font-size: 11px;
  text-decoration: underline;
  margin-left: 5px;
}

.bold_mak {
  font-weight: 800;
}

.loader-wrapper {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: grid;
  place-items: center;
}

.loader {
  border: 16px solid #e3e3e3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

.selectcategory {
  border: 1px solid #d1d3e2;
  padding: 4px;
  border-radius: 5px;
  cursor: pointer;
}

.subcategory {
  border: 1px solid #d1d3e2;
}

.subcategory li {
  cursor: pointer;
}


.showdiv{
  display: block;
}
.hidediv{
  display: none;
}

.subcategory ul {
  list-style: none;
}

input[type="search"],
select,
input[type="date"] {
  border: 1px solid #aaa;
  border-radius: 3px;
  padding: 5px;
  background-color: transparent;
  margin-left: 3px;
  /* margin-bottom: 10px; */
}

/* Igm on_issues Css start */
#StatusLight input[type="radio"].OPEN {
  accent-color: #ff0000;
}

#StatusLight input[type="radio"].CREATED {
  accent-color: #ff0000;
}

#StatusLight input[type="radio"].RESOLVED {
  accent-color: #00ff00;
}

#StatusLight input[type="radio"].Closed {
  accent-color: #00ff00;
}

#StatusLight input[type="radio"].PROCESSING {
  accent-color: #ffcc00;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  width: 5px;
  /* background: #f5f5f5; */
}

::-webkit-scrollbar-thumb {
  width: 1em;
  background-color: #ddd;
  /* outline: 1px solid slategrey; */
  border-radius: 1rem;
}

.text-small {
  font-size: 0.9rem;
}

/* Igm on_issues Css End */

.genericlabel {
  font-size: 14px;
  font-weight: 600;
  color: black;
}


.multiOrderStatusSelector{
  width: 400px;
}

/*Login Css  */
.main_container {
  width: 65% !important;
}

.main_container .image_wrapper .imagesSetup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.login_btn {
  background: #11335b;
}

.main_container .image_wrapper .image1 img {
  border-bottom-left-radius: 15px;
  border-top-left-radius: 15px;
}

.main_container .loginFormat .form_padding {
  padding-inline-start: 30px !important;
}

::placeholder {
  padding-left: 10px;
}

.loginFormat .Icons {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translate(-50%, -50%);
  line-height: 1;
  color: #000000;
}


.category_list {
  margin-left: 20px;
  max-height: 300px;
  overflow: scroll;
}
.selectcategory {
  border: 1px solid #d1d3e2;
  padding: 5px;
  cursor: pointer;
  border-radius: 4px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media(max-width: 461px){
  .main_container {
    width: 100% !important;
  }

}

@media(max-width: 767px){
  .showdiv{
    display: none;
  }
  .hidediv{
    display: block;
  }
  
}

/*  */
.list-group-item {
  background-color: #f8f9fa; /* Light background for better contrast */
  transition: background-color 0.3s;
}

.list-group-item:hover {
  background-color: #e2e6ea; /* Slightly darker on hover */
}

.modal-title {
  font-weight: 600; /* Bold title */
}

.fw-bold {
  color: #495057; /* Darker color for better readability */
}


/*  */
