.orderdetails{
    border: 1px solid lightslategray;
    box-shadow: 2px 2px ;
    width: 82%;
    padding: 5px 20px;
    border-radius: 10px;
    width: 90%;
    box-shadow: -5px 7px 12px -7px rgba(0,0,0,0.75);

}

.orderdetails label{
    font-size: 14px;
    font-weight: 500;
    color: black;
}

.orderdetails span{
    text-transform: capitalize;
}