.doneButton {
    background: #105293;
    color: white;
    width: 100px;
}

.tablecellBox{
    
    width: 100px;
}

.variantAddBtn{
    border: none;
    padding: 2px 10px;
    border-radius: 10px;
    margin-left: 10px;
    font-weight: 600;
}