.productTemplateMain {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* height: 150px; */
    /* background-color: #F7F8FF; */
    margin: 14px 0px;
    display: none;
    background: url("../../icons/rectangle.png");
    position: relative;
    background-repeat: no-repeat;
    background-position: right;
    border-radius: 13px;
}

.productTemplateImage {
    /* border: 1px solid red; */
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;



}

.productTemplateImage img {
    width: 100%;
    height: 100%;
    /* border: 1px solid rgb(27, 2, 255); */
    object-fit: contain;
    border-top-left-radius: 9px;
    border-bottom-left-radius: 9px;
}

.productTemplateDesc {
    width: 75%;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-bottom: 5px;
    /* gap: 6px; */
    /* border: 1px solid rgb(4, 245, 104); */
}

.productTemplateDesc button {
    background-color: #02D757;
    border: none;
    outline: none;
    width: 58.94px;
    height: 21.84px;
    line-height: 14px;
    color: white;
    font-weight: 500;
    font-size: 12px;

}

.productTemplateDesc p:nth-child(3) {
    color: #4F4F4F;
    font-weight: 800;
    font-size: 14px;
    line-height: 14px;
    padding-bottom: 3px
}

.productTemplateDesc p:nth-child(1) {
    color: #4F4F4F;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;

}

.productTemplateButtons {
    padding-top: 6px;
    display: flex;
    flex-direction: column;
}

.approvalStatus{
    background-color: red !important;
    width: 54.94px !important;
    height: 21.84px !important;

}
.pop-up{
    position: relative;
}
.popupClose{
     position: absolute;
     top: -1px;
     right: 5px;
     color: blue;
     font-weight: 700;
     font-size: 15px;
     cursor: pointer;
}

@media screen and (max-width: 786px) {
    .productTemplateMain {
        display: block;
        padding: 5px;
    }
}

@media screen and (max-width: 375px) {

    .productTemplateDesc {
        gap: 3px;
    }

    .productTemplateDesc p {
        font-size: 12px !important;
    }

    .productTemplateImage img {
        border-top-left-radius: 14px;
        border-bottom-left-radius: 14px;
    }

    .productTemplateDesc p:nth-child(3) {
        padding-bottom: 0px
    }

    .productTemplateDesc button {
        width: 53.94px;
        height: 17.84px;
    }
}