.selectboxwidth{
    width: 200px;
}

@media screen and (max-width: 480px) {
    .selectboxwidth{
        width: 291px;
    }

    .widthControl{
        width: 100%;
    }
    
}