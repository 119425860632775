.switch {
    position: relative;
    display: inline-block;
    width: 53px !important;
    height: 28px !important;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  .modalTrigerButton{
    background: lightgrey;
    border-radius: 14px;
    font-size: 11px;
    color: black;
    font-weight: 600;
    cursor: pointer;
    padding: 6px;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 22px !important;
    width: 22px !important;
    left: 3px !important;
    bottom: 3px;
    background-color: white;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #105293;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

  .prod_desc{
    font-size: 12px;
    width: 57%;
    font-weight: 500;
    margin-left: 3px;
    display: flex;
    align-self: flex-start;
  }


  .searchDiv{
    border: 1px solid #105293;
  }

  .searchDiv select {
    font-size: 13px;
    font-weight: 600;
    background: #105293;
    color: white;
  }

  .searchDiv input{
    border: none;
    outline: none;
    font-size: 12px;
    
  }

  .btnvar{
    height: 31px !important;
  }

  .filterselection{
    /* border: 1px solid red; */
    border: 1px solid grey;
    border-radius: 10px;
    width: 100%;
    margin-top: 10px;
    background-color: white;
    min-height: 250px;
    position: absolute;
    z-index: 10;
    padding-bottom: 20px;
  }

  .functionalButtons {
    background: lightgrey;
    border-radius: 12px;
    font-size: 12px;
    width: 70px;
    height: 25px;
    cursor: pointer;
    padding-top: 3px;
    color: black;
    font-weight: 700;
    text-align: center;
  }
  
  .functionalButtons:hover{
    background: rgb(122, 121, 121);
  }