.bloc_tabs {
    display: flex;
    list-style: none;
}

.bloc_tabs li {
    font-size: 16px;
    letter-spacing: .5px;
}

.tabs {
    padding: 10px 10px 10px 15px;
    text-align: center;
    cursor: pointer;
    box-sizing: content-box;
    position: relative;
    outline: none;
    background: transparent;
}

.active_tabs {
    color: #36b9cc;
    border-bottom: 3px solid;
}

.content_tabs {
    flex-grow: 1;
}

.contentShow {
    width: 100%;
    height: 100%;
    display: none;
}

.active_content {
    display: block;
}

.search_input_container {
    display: flex;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .search_select,
  .search_input {
    border: none !important;
  }
  
  .search_select {
    /* margin-right: 10px;
    flex-shrink: 0;  */
  }