.tracking-container {
    width: 100%;
    padding: 20px;
  }
  
  .tracking-progress {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;
  }
  
  .step-container {
    display: flex;
    align-items: center; /* Align icons horizontally */
    position: relative;
  }
  
  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    transition: all 0.3s ease;
  }
  
  .step-icon {
    font-size: 24px;
    margin-bottom: 8px;
  }
  
  .step p {
    margin: 0;
    font-size: 12px;
  }
  
  .step.active {
    font-weight: bold;
    color: #007bff;
  }
  
  .step.completed {
    color: #28a745;
  }
  
  .step-line {
    width: 40px; /* Adjust the width of the line */
    height: 2px; /* Line thickness */
    background-color: #007bff; /* Line color */
  }
  
  .active-step-content {
    margin-top: 20px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
  }
  