.togglePerAmount {
    background-color: grey;

}

.perAmount {
    text-align: center;
    width: 50%;
    padding: 0.25rem;
    font-size: 13px;
    padding-top: 0.5rem;

}

.addButtonSection {
    padding: 9px;
    position: sticky;
    /* position: fixed; */
    bottom: -2px;
    background: white;
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.radioStyle{
    /* style={{ width: "22px", height: "16px" }} */
    width: 22px !important;
    height: 16px !important;

}