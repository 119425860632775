.br1 {
    border-radius: 8px;  
  }
  .w80 {
     width: 80%;
  }
  .card1 {
    border: 2px solid #fff;
    box-shadow:0px 0px 10px 0 #a9a9a9;
    padding: 30px 40px;
    width: 95%;
    margin: 50px auto;
  }
  .profilePic {
    height: 65px;
    width: 65px;
    border-radius: 50%;
  }
  .comment1 {
    height: 10px;
    background: #777;
    margin-top: 20px;
  }
  
  .wrapper1 {
    width: 0px;
    animation: fullView 0.5s forwards linear;
  }
  
  @keyframes fullView {
    100% {
      width: 100%;
    }
  }

  .animate1 {
    animation : shimmer 2s infinite;
    background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
    background-size: 1000px 100%;
 }

 @keyframes shimmer {
    0% {
      background-position: -1000px 0;
    }
    100% {
      background-position: 1000px 0;
    }
  }