@font-face {
    font-family: 'SF Pro Display';
    src: url('../../../fonts/SFPRODISPLAYREGULAR.OTF') format('truetype');
    font-weight: normal;
    font-style: normal;
}


.side_bar {
    min-height: 100vh;
    width: 21%;
    padding: 15px;
    background-color: #105293;
    /* background-color: #40424b; */
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-family: 'SF Pro Display';
}


.theme_ {
    display: flex;
    min-height: 90vh;
    padding: 0 !important;
}

.theme_sidemenuDesktop {
    display: block;
    width: 18%;
    background-color: #105293;
    /* background-color: #40424b; */
}


.tabsInnerDiv {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 14px;
    font-size: 14px;
    letter-spacing: 1px;
}

.sidemenu_mobile {
    display: none;

}

.listItems {
    display: flex;
    width: 75%;
    color: white;
    /* border: 1px solid red; */
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    position: relative
}



.listItems span {
    font-size: 14px !important;
    color: white;
    letter-spacing: 1px;

}

.listItems span:nth-child(3) {
    display: inline-block;
    position: absolute;
    right: 5px;
    top: -1px;
    background: #0097ff;
    border-radius: 50%;
    width: 24px;
    height: 21px;
    font-weight: 800;
    text-align: center;
    color: white;
}

.side_bar>div, .side_bar section>div {
    color: #2392FF !important;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 2px;
    text-transform: uppercase;

}

.sidebar_navbar{
    display: none;
}

@media screen and (max-width: 1024px) {
    .side_bar {
        width: 25%;
    }
}


@media screen and (max-width: 768px) {

    .icon_color{
        color: #0097ff;
    }
    .sidebar_navbar{
        display: block;
    }
    .sidemenu_desktop {
        display: none;
    }

    .sidemenu_mobile {
        display: block;
    }

    .side_bar {
        background-color: white;
        width: 100%;
        min-height: 75vh;
    }

    .theme_sidemenuDesktop {
        display: none;
    }

    .theme_ {
        overflow: scroll;
    }

    .sideBarLogo {
        display: none;
    }

    .side_bar li:nth-child(3) {
        /* background-color: red; */
        height: 53px;
    }

    .side_bar li:nth-child(3) span {
        font-size: 23px;
        font-weight: 700;
        color: #2392FF;
    }

    .side_bar li i {
        color: #2392FF !important;
        font-size: 22px;
    }

    .side_bar li span {
        color: gray;
        letter-spacing: 1px;
        font-size: 14px;
    }



    .side_bar>hr {
        background-color: grey;
    }

    .side_bar li div>div {
        background-color: #006CD6 !important;
    }

    .collapseitemText {
        color: white;
    }

    .listItems i {
        font-size: 14px !important;
        animation-name: floatingIcons;
        animation-duration: 0.5s;

    }

    @keyframes floatingIcons {
        0% {
            transform: translate(200px) scale(0);
            opacity: 0;
        }

        100% {
            transform: translate(0px) scale(1);
            opacity: 1;
        }
    }



    .listItems {
        width: 95%;
        color: rgba(255, 255, 255, .8);
    }

    /* .listItems ~ i{
        margin-right: 30px;
    } */




}

@media screen and (max-width: 461px){
    .listItems span {
        color: gray;
        letter-spacing: 1px;
    
    }

    .tabsInnerDiv{
        background-color: #006CD6 !important;
    }
}
