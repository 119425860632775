.main {
    /* border: 2px solid grey; */
    padding: 3px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    margin: 4px 2px;
}

.main div:nth-child(1) {
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 15px;
}

.main div:nth-child(1) span {
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: blue;
    color: white;
    text-align: center;
    padding-top: 3px;
   
}

.main div:nth-child(2) {
    width: 85%;
    color: white
}

.main div:nth-child(2) p:nth-child(1){
    font-weight: 600;
}
.main div:nth-child(2) p:nth-child(2){
    font-size: 13px;
}

.bgtype1{
    background-color: #006CD6    ;
}
.bgtype2{
    background-color: #E85E5B
    ;
}
.bgtype3{
    background-color: #FF8139    ;
}
.bgtype4{
    background-color: #FFBA0C
    ;
}