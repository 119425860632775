.spinnerBox{
    transform: rotate(46deg);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    border-radius: 50%;
}
.spinnerBox p{
    transform: rotate(-47deg);
    font-size: 15px;
    font-weight: bold;
}
.spinnerBox2{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border-width: 15px 16px 16px;
    border-style: solid;
    border-color: blue rgb(243, 243, 243) rgb(243, 243, 243);
    border-image: initial;
}

.mobileBoxes{
    width: 45%;
    box-shadow: grey 3px 3px 6px 1px;
    border: 1px solid grey;
    border-radius: 10px;
    background: white;
}

.mobileBoxes .counti {
    font-size: 29px;
    color: black;
    font-weight: bold;
    /* margin: 0.25rem 0rem; */
}

.ticketlist{
    width: 60%;
    /* max-height: 90vh; */
}
.countmain{
    width: 35%;
    gap: 1rem;
}

.graphmain{
    width: 100%;
}

.main{
    flex-direction: column;
}

.piechart{
    display: flex;
    height: 200px;
  

}

@media screen  and (max-width: 426px){
    .ticketlist{
        width: 100%;
        max-height: 400px;
        overflow: scroll;
    }

    .countmain{
        width: 100%;
    }

    .graphmain{
        width: 100%;
    }

    .main{
        flex-direction: column-reverse;
    }

    .piechart{
        display: none;
    }
    
    
}

.fixed_width {
    width: 300px; 
}

.search_filter {
    overflow: auto;
}

.search_filter::-webkit-scrollbar {
    width: 3px;
    height: 3px;
}

.search_filter::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 1rem; 
}

.search_filter::-webkit-scrollbar-thumb {
    background-color: #007bff; 
    border-radius: 1rem;
}
