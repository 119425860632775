.file-input-container {
    position: relative;
    display: inline-block;
  }
  
  .custom-file-input-button {
    display: inline-block;
    padding: 5px 17px;
    background-color: #007bff; /* Your desired button color */
    color: #fff; /* Button text color */
    cursor: pointer;
    border: none;
    border-radius: 5px;
  }
  
  /* Style the label like a button */
  .custom-file-input-button:hover {
    background-color: #0056b3; /* Button color on hover */
  }
  
  /* Hide the file input */
 .input_file {
    display: none;
  }
  