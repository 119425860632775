td span.badge {
  cursor: pointer;
}

input[type=radio] {
  border: 0px;
  width: 100%;
  height: 2em;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  width: 1em;
  background-color: #ddd;
  border-radius: 1rem;
}

.text-small {
  font-size: 0.9rem;
}

.messages.chat-box {
  height: 335px;
  overflow-y: scroll;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="media"] {
    width: 100%!important;
    margin-top: 10px;
  }
}