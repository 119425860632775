.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}



/* The Modal (background) */
.popupbg {
  background: #0000004f;
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  overflow: none ;
  /* opacity: 0.7; */
}

/* Modal Content/Box */
.popup {
  position: fixed;
  background: #fff;
  top: 10%;
  left: 50%;
  height: 80vh;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 1;
  transform: translateX(-50%);
  width: 80%;
}




/* / cancelButton / */
.cancelorderpopup {
  position: fixed;
  background: #fff;
  top: 20%;
  left: 50%;
  z-index: 1;
  transform: translateX(-50%);
  width: 45%;
}
.cancelpopupoverlay {
  background: #000;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1;
  right: 0;
  height: 100vh;
  opacity: 0.7;
}
.btnHeight{
  height: fit-content !important;
}
.btnAlign{
  justify-content: end;
}

.orderimg{
  width: 100%;
}
/*  */
.reason_code{
  appearance: none;
}
.itemFDetails select:disabled {
  color: #484848;
}

.dimensionMain{
  width: 65%;
}
.providersModal{
  width: 60%;
}
@media screen and (max-width: 767px) {
  .cancelorderpopup {
    width: 94%;
  }

  .providersModal{
    width: 100%;
  }
  .btnHeight{
    height: fit-content !important;
  }
  .btnAlign{
    justify-content: start;
    padding: 0;
    margin-left: -8px;
  }
  .dimensionMain{
    width: 100%;
  }
}

.allkeys{
  color: black;
  font-size: 15px;
  font-weight: 500;
}

/* styles.css */
.red-ok-button {
  background-color: red !important;
  color: white !important; 
}

.updating-ok-button {
  background-color: #1890ff !important; 
  color: white !important; 
}


@media screen and (max-width: 500px){
  .orderimg{
    width: 100%;
  }
}
@media screen and (max-width: 400px){
  .orderimg{
    width: 80%;
  }
}

@media screen and (max-width: 770px) {
  .PMOrdeList{
    flex-direction: column;
  }
  
}